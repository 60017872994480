import * as React from "react"
import Layout from "../../Layout/layout"
import "./campaign_details.css"
import { useEffect, useState } from "react"
import Axios from "axios"
import { getAccessToken } from "../../../services/auth"
import CampaignTitleName from "./campaignTitleName"
import CampaignGoal from "./campaignGoal"
import Members from "./members"
import MorePanel from "./morePanel"
import { Form } from "react-bootstrap"
import HistoryNote from "./HistoryNote/historyNote"
import AxiosInstance from "../../../services/axiosInstance"

const Detail = ({ selectedCampaignId }) => {
  const campaignApi = "/campaign",
    [campaign, setCampaign] = useState(null),
    [campaignError, setCampaignError] = useState(false),
    [loadCampaignDetails, setLoadCampaignDetails] = useState(true)

  const getCampaignData = async selectedCampaignId => {
    await AxiosInstance.get(`${campaignApi}/detail/${selectedCampaignId}`).then(response => {
      fillCampaignData(response.data.campaign)
    })
  }

  const [campaignName, setCampaignName] = useState("")
  const [campaignTempName, setCampaignTempName] = useState(""),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [campaignPermission, setCampaignPermission] = useState(null),
    [campaignPrivacy, setCampaignPrivacy] = useState(null),
    [campaignRole, setCampaignRole] = useState(null),
    [campaignRoleType, setCampaignRoleType] = useState(null),
    [historyNotes, setHistoryNotes] = useState([]),
    [historyBlock, setHistoryBlock] = useState(true)

  const fillCampaignData = campaignData => {
    setCampaign(campaignData)
    setCampaignName(campaignData.name)
    setCampaignTempName(campaignData.name)
    setTags(campaignData.tags)
    setTempTags(campaignData.tags)
    setCampaignPermission(campaignData.permission)
    setCampaignPrivacy(campaignData.privacy)
    setCampaignRole(campaignData.role)
    setCampaignRoleType(campaignData.roleType)
    setHistoryNotes(campaignData.historyNotes)
  }

  const saveCampaignDetails = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    return new Promise(async () => {
      await AxiosInstance.post(`/campaign/edit/${campaign.id}`, {
        privacy: formData.get("campaignPrivacy"),
        permission: formData.get("campaignPermissions"),
        roleType: formData.get("campaignRoleType"),
      })
        .then(function (response) {
          if (response.status === 200) {
            return false
          }
        })
        .catch(function (error) {})
    })
  }

  useEffect(() => {
    if (loadCampaignDetails) {
      setLoadCampaignDetails(false)
      getCampaignData(selectedCampaignId)
    }
  })

  return (
    <Layout>
      <div id="right-section" className="h-100">
        {campaign && (
          <Form onSubmit={saveCampaignDetails}>
            <div className="row g-0" id={"contactGroupViewTemplate"}>
              <div className="col" id="page-urls">
                <div className="row">
                  <div className="col-lg-6 contactGroupViewLeftSection">
                    <div className="row g-0">
                      <div className="col-sm-12 mb-3">
                        <CampaignTitleName
                          campaignId={campaign.id}
                          name={campaignName}
                          setName={setCampaignName}
                          tempName={campaignTempName}
                          setTempName={setCampaignTempName}
                        />
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col-lg-12 mb-3 CGDetailsContainer">
                        <CampaignGoal campaign={campaign} />

                        <div className="row g-0">
                          <div className="col p-3 position-relative">
                            <div className="progress mt-2 campaignProgress">
                              <div
                                className="progress-bar CP_not_started"
                                role="progressbar"
                                aria-label="Not started"
                                style={{ width: "50%" }}
                                aria-valuenow="3545"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <div
                                className="progress-bar bg-warning CP_started"
                                role="progressbar"
                                aria-label="Started"
                                style={{ width: "30%" }}
                                aria-valuenow="56"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <div
                                className="progress-bar bg-success CP_completed"
                                role="progressbar"
                                aria-label="Completed"
                                style={{ width: "20%" }}
                                aria-valuenow="90"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <div
                                className="progress-bar CP_missing_merge_tags"
                                role="progressbar"
                                aria-label="Missing merge-tags"
                                style={{ width: "0%" }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <div
                                className="progress-bar bg-danger CP_invalid_emails"
                                role="progressbar"
                                aria-label="Invalid emails"
                                style={{ width: "0%" }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <div
                                className="progress-bar CP_unsubscribed"
                                role="progressbar"
                                aria-label="Unsubscribed"
                                style={{ width: "0%" }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <div
                                className="progress-bar CP_do_not_contact"
                                role="progressbar"
                                aria-label="Do not contact"
                                style={{ width: "0%" }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <div className="CG_CTA_Container mt-2">
                              <ul>
                                <li className="CP_text_not_started">
                                  <i className="bi bi-hourglass-split" /> Not started: 3545
                                </li>
                                <li className="text-warning">
                                  <i className="bi bi-clock" /> Started: 56
                                </li>
                                <li className="text-success">
                                  <i className="bi bi-check2" /> Completed: 90
                                </li>
                                <li className="text-yellow">
                                  <i className="bi bi-code-slash" /> Missing merge-tags: 0
                                </li>
                                <li className="text-danger">
                                  <i className="bi bi-x-circle" /> Invalid emails: 0
                                </li>
                                <li className="CP_text_soft_bounce">
                                  <i className="bi bi-exclamation-triangle-fill" /> Soft bounce: 0
                                </li>
                                <li className="text-danger">
                                  <i className="bi bi-x-lg" /> Hard bounce: 0
                                </li>
                                <li className="text-dark">
                                  <i className="bi bi-clock" /> Unsubscribed: 0
                                </li>
                                <li className="text-black-50">
                                  <i className="bi bi-x-lg" /> Do not contact: 0
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0">
                          <div className="col-lg-6 p-3">
                            Last Send:{" "}
                            <time
                              className="timeago"
                              datetime="2022-06-30"
                              data-bs-toggle="tooltip"
                              title="30th Jun 2022 10:00:00"
                            ></time>
                          </div>
                          <div className="col-lg-6 p-3 text-end">
                            Next Send:{" "}
                            <time
                              className="timeago"
                              datetime="2022-07-31"
                              data-bs-toggle="tooltip"
                              title="31st Jul 2022 10:00:00"
                            ></time>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row g-0">
                      <div className="col-lg-12 mb-3">
                        <div className="row g-0">
                          <Members campaignId={campaign.id} />

                          <div className="col-lg-12 mb-3" id="calendar_panel">
                            <div className="accordion-item panel panel-pink">
                              <div
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#calendar_container"
                                aria-expanded="false"
                                aria-controls="calendar_container"
                              >
                                <i className="bi bi-calendar-check me-2" /> Calendar
                              </div>
                              <div
                                id="calendar_container"
                                className="accordion-collapse collapse"
                                aria-labelledby="calendar_container"
                              >
                                <div className="accordion-body">
                                  <div className="row g-0">
                                    <div className="col tableContainer">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="float-end">
                                            <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
                                              <li className="nav-item">
                                                <button type="button" className="btn btn-primary mt-1 me-1">
                                                  {" "}
                                                  <i className="bi bi-plus" /> Add New
                                                </button>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                <a
                                                  className="nav-link active"
                                                  id="upcoming-event-tab"
                                                  data-bs-toggle="tab"
                                                  data-bs-target="#upcoming-event-tab-pane"
                                                  role="tab"
                                                  aria-controls="upcoming-event-tab-pane"
                                                  aria-selected="false"
                                                >
                                                  <span data-bs-toggle="tooltip" title="Upcoming Events">
                                                    <i className="bi bi-arrow-clockwise" />
                                                  </span>
                                                </a>
                                              </li>
                                              <li className="nav-item" role="presentation">
                                                <a
                                                  className="nav-link"
                                                  id="past-event-tab"
                                                  data-bs-toggle="tab"
                                                  data-bs-target="#past-event-tab-pane"
                                                  role="tab"
                                                  aria-controls="past-event-tab-pane"
                                                  aria-selected="false"
                                                >
                                                  <span data-bs-toggle="tooltip" title="Past Events">
                                                    <i className="bi bi-arrow-counterclockwise" />
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 tableFadeOut">
                                          <div className="tab-content autoAdjustSearchBox" id="CalendarTabs">
                                            <div
                                              className="tab-pane fade show active"
                                              id="upcoming-event-tab-pane"
                                              role="tabpanel"
                                              aria-labelledby="upcoming-event-tab"
                                              tabIndex="0"
                                            >
                                              <table
                                                id="tblUpcomingEvents"
                                                className="table dataTable"
                                                cellSpacing="0"
                                                width="100%"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th width="70%" className="nowrap">
                                                      Event
                                                    </th>
                                                    <th width="30%" className="d-none d-md-table-cell">
                                                      Scheduled
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>Event 1</td>
                                                    <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2022-12-25" />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Event 2</td>
                                                    <td className="d-none d-md-table-cell">
                                                      <time className="timeago" dateTime="2022-12-31" />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div
                                              className="tab-pane fade"
                                              id="past-event-tab-pane"
                                              role="tabpanel"
                                              aria-labelledby="past-event-tab"
                                              tabindex="0"
                                            >
                                              <table
                                                id="tblPastEvents"
                                                className="table dataTable"
                                                cellspacing="0"
                                                width="100%"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th width="70%" className="nowrap">
                                                      Event
                                                    </th>
                                                    <th width="30%" className="d-none d-md-table-cell">
                                                      Scheduled
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>Event 1</td>
                                                    <td className="d-none d-md-table-cell">
                                                      <time className="timeago" datetime="2022-10-24"></time>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mb-3" id="accounting_panel">
                            <div className="accordion-item panel panel-blue">
                              <div
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#account_container"
                                aria-expanded="false"
                                aria-controls="account_container"
                              >
                                <i className="bi bi-card-list me-2" /> Accounting
                              </div>
                              <div
                                id="account_container"
                                className="accordion-collapse collapse"
                                aria-labelledby="account_container"
                              >
                                <div className="accordion-body">
                                  <div className="row tabularMenuContainer">
                                    <div className="col-sm-12">
                                      <div className="float-end">
                                        <ul
                                          className="nav nav-tabs swiftCloudTabs d-none d-md-flex"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <div className="btn-group btnAddNewDropdown" role="group">
                                              <button
                                                id="btnGroupDrop1"
                                                type="button"
                                                className="btn btn-primary dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="bi bi-plus-lg" />{" "}
                                                <span className="d-none d-md-inline">Add New</span>
                                              </button>
                                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add_new_transaction"
                                                  >
                                                    <i className="bi bi-currency-rupee" /> Manual Record
                                                  </a>{" "}
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="#">
                                                    <i className="bi bi-list-ul" /> Create Invoice
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item btnAddNewSubscription" href="#">
                                                    <i className="bi bi-repeat" /> Agreement
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li className="nav-item">
                                            <a
                                              className="nav-link active"
                                              id="history-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#history"
                                              role="tab"
                                              aria-controls="history"
                                              aria-selected="false"
                                            >
                                              <span data-bs-toggle="tooltip" data-bs-title="History / Paid">
                                                <i className="bi bi-arrow-counterclockwise" />
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link"
                                              id="projected-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#projected"
                                              role="tab"
                                              aria-controls="projected"
                                              aria-selected="false"
                                            >
                                              <span
                                                data-bs-toggle="tooltip"
                                                data-bs-title="Projected / Upcoming"
                                              >
                                                <i className="bi bi-arrow-clockwise" />
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link"
                                              id="agree-subscription-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#agree-subscription"
                                              role="tab"
                                              aria-controls="agree-subscription"
                                              aria-selected="false"
                                            >
                                              <span
                                                data-bs-toggle="tooltip"
                                                data-bs-title="Agreements & Subscriptions"
                                              >
                                                <i className="bi bi-repeat" />
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link"
                                              href="drive.html"
                                              data-bs-toggle="tooltip"
                                              data-bs-title="Drive"
                                            >
                                              <i className="bi bi-database-fill" />
                                            </a>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link"
                                              href="contacts.html"
                                              data-bs-toggle="tooltip"
                                              data-bs-title="Contacts"
                                            >
                                              <i className="bi bi-person-fill" />
                                            </a>
                                          </li>
                                          <li className="nav-item nav-item-trash" role="presentation">
                                            <a
                                              className="nav-link"
                                              data-bs-toggle="tooltip"
                                              data-bs-title="View Templates"
                                            >
                                              <i className="bi bi-journals" />
                                            </a>
                                          </li>
                                          <li className="nav-item nav-item-trash" role="presentation">
                                            <a
                                              className="nav-link"
                                              data-bs-toggle="tooltip"
                                              data-bs-title="Trash"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </li>
                                        </ul>

                                        <ul
                                          className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <div className="btn-group btnAddNewDropdown" role="group">
                                              <button
                                                id="btnGroupDrop2"
                                                type="button"
                                                className="btn btn-primary dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="bi bi-plus-lg" />
                                              </button>
                                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add_new_transaction"
                                                  >
                                                    <i className="bi bi-currency-rupee" /> Manual Record
                                                  </a>{" "}
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="#">
                                                    <i className="bi bi-list-ul" /> Create Invoice
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item btnAddNewSubscription"
                                                    href="javascript:;"
                                                  >
                                                    <i className="bi bi-repeat" /> Agreement
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>

                                            <div className="btn-group btnDriveView" role="group">
                                              <button
                                                id="btnGroupDrop3"
                                                type="button"
                                                className="btn btn-default dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="bi bi-database-fill" />
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <a
                                                    className="dropdown-item active"
                                                    id="history-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#history"
                                                    role="tab"
                                                    aria-controls="history"
                                                    aria-selected="false"
                                                  >
                                                    <i className="bi bi-arrow-counterclockwise" /> History /
                                                    Paid
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    id="projected-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#projected"
                                                    role="tab"
                                                    aria-controls="projected"
                                                    aria-selected="false"
                                                  >
                                                    <i className="bi bi-arrow-clockwise" /> Projected /
                                                    Upcoming
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    id="agree-subscription-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#agree-subscription"
                                                    role="tab"
                                                    aria-controls="agree-subscription"
                                                    aria-selected="false"
                                                  >
                                                    <i className="bi bi-repeat" /> Agreements & Subscriptions
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="drive.html">
                                                    <i className="bi bi-database-fill" /> Drive
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="contacts.html">
                                                    <i className="bi bi-person-fill" /> Contacts
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="#">
                                                    <i className="bi bi-journals" /> View Templates
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="#">
                                                    <i className="bi bi-trash-fill" /> Trash
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="tab-content">
                                        <div
                                          className="tab-pane fade show active"
                                          id="history"
                                          role="tabpanel"
                                          aria-labelledby="history-tab"
                                        >
                                          <table
                                            id="tblAccountHistory"
                                            className="table dataTable"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <thead>
                                              <tr>
                                                <th width="5%">
                                                  <a
                                                    tabindex="0"
                                                    className="history_table_action table-head-icon"
                                                  >
                                                    <i className="bi bi-gear-fill" />
                                                  </a>
                                                </th>
                                                <th width="5%" className="d-none d-md-table-cell">
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th
                                                  width="10%"
                                                  className="d-none d-md-table-cell text-center"
                                                >
                                                  Date{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th width="40%">
                                                  Type / Title / Memo{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th width="10%" className="text-end">
                                                  Amount{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th
                                                  width="10%"
                                                  className="d-none d-md-none display-none balance-col"
                                                >
                                                  Balance
                                                </th>
                                                <th
                                                  width="20%"
                                                  className="d-none d-md-none d-lg-table-cell"
                                                ></th>
                                              </tr>
                                              <tr className="display-none history_table_action_container">
                                                <td colSpan="7" className="text-left">
                                                  <div className="batch-action-container">
                                                    <button className="btn btn-trash">
                                                      <i className="bi bi-trash-fill" /> Move to Trash
                                                    </button>
                                                    <button
                                                      className="btn medium_purple"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Merge Duplicate Records"
                                                    >
                                                      <i className="bi bi-share-fill flip-horizontal" /> Merge
                                                    </button>
                                                    <button
                                                      className="btn btn-success"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Nest related records into a single row to keep things organized."
                                                    >
                                                      <i className="bi bi-bezier" /> Nest
                                                    </button>
                                                    <button
                                                      className="btn btn-default"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Un-Nest"
                                                    >
                                                      <i className="bi bi-bezier2" /> Un-Nest
                                                    </button>
                                                    <button
                                                      className="btn btn-secondary"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Tags"
                                                    >
                                                      <i className="bi bi-tags-fill" /> Tags
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr className="display-none history_table_action_container history_filter_table_action_container">
                                                <td colSpan="7" className="text-left">
                                                  <div className="batch-action-container">
                                                    <div className="row no-gutters">
                                                      <div className="col-lg-12">
                                                        <div className="form-inline mb-3">
                                                          <label className="mb-0 mr-2">By Type</label>
                                                          <select className="form-select mb-0">
                                                            <option value="Image">Image</option>
                                                            <option value="Audio">Audio</option>
                                                            <option value="Video">Video</option>
                                                            <option value="Archive">Archive</option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="row no-gutters">
                                                      <div className="col-lg-8 col-md-8 col-sm-12">
                                                        <div className="form-inline mb-2">
                                                          <h4>
                                                            <i className="bi bi-funnel-fill" /> Show me
                                                            <select className="form-select mb-0 mx-2">
                                                              <option value="Contacts">Contacts</option>
                                                              <option value="Files_Workrooms">
                                                                Files & Workrooms
                                                              </option>
                                                              <option value="Accounting_Records">
                                                                Accounting Records
                                                              </option>
                                                            </select>
                                                            that are…
                                                          </h4>
                                                        </div>
                                                        <div className="mb-3">
                                                          <select className="form-select mb-3">
                                                            <option value="Tagged_with…">Tagged with…</option>
                                                            <option value="Selling_Opportunities">
                                                              Selling Opportunities
                                                            </option>
                                                          </select>
                                                          <button
                                                            type="button"
                                                            className="btnAddNew"
                                                            id="btnAddNew"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Add New"
                                                          ></button>
                                                        </div>
                                                        <div className="mb-3">
                                                          <label>& Add a Custom Column</label>
                                                          <input
                                                            type="text"
                                                            name="addCustomColumn"
                                                            placeholder="tag-score"
                                                            className="form-control"
                                                          />
                                                        </div>
                                                        <div className="mb-3">
                                                          <label>& then sort the records by…</label>
                                                          <input
                                                            type="text"
                                                            name="sortRecordsBy"
                                                            placeholder="tag-score"
                                                            className="form-control"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-1"></div>
                                                      <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                                                        <h4>
                                                          <i className="bi bi-bullseye" /> Saved Filtered
                                                          Views
                                                        </h4>
                                                        <ul>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Helpdesk Tasks
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Homebuyer Selling Opps
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Open Client Projects
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              UserSavedFilterLabelHere
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr className="negative_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="H_ID12"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="H_ID12"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_green">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Projected"
                                                  >
                                                    <i className="bi bi-clock" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  01/12/2022
                                                </td>
                                                <td className="col_to_from">Facebook Ads Cost</td>
                                                <td className="text-end">-122.00</td>
                                                <td className="text-center d-none d-md-none display-none balance-col balance-color-red"></td>
                                                <td className="text-end colAction d-none d-md-none d-lg-table-cell">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="nested_row neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="H_ID1"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="H_ID1"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_yellow">
                                                      <i className="bi bi-check2" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <a
                                                    data-type="invoice"
                                                    className="expand_child_row"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Expand Detailed View"
                                                  >
                                                    <i className="bi bi bi-plus-circle-fill" />
                                                  </a>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  02/13/2017
                                                </td>
                                                <td className="col_to_from">
                                                  {" "}
                                                  Sale of Product 1 SwiftCloud Support
                                                </td>
                                                <td className="text-end">100.00</td>
                                                <td className="text-center d-none d-md-none display-none balance-col balance-color-red">
                                                  (-200.00)
                                                </td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-colAction btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                            <tfoot>
                                              <tr className="accouting_total_col">
                                                <th colSpan="4" style={{ extAlign: "right" }}>
                                                  Total:
                                                </th>
                                                <th colSpan="3" className="d-none d-md-block d-lg-table-cell">
                                                  &nbsp;
                                                </th>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>

                                        <div
                                          className="tab-pane fade"
                                          id="projected"
                                          role="tabpanel"
                                          aria-labelledby="projected-tab"
                                        >
                                          <table
                                            id="projected_table"
                                            className="table table-striped customCheckbox table-head-color dataTable no-footer"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <thead>
                                              <tr>
                                                <th width="5%">
                                                  <a
                                                    tabindex="0"
                                                    className="projected_table_action table-head-icon"
                                                  >
                                                    <i className="bi bi-gear-fill" />
                                                  </a>
                                                </th>
                                                <th width="5%" className="d-none d-md-table-cell text-center">
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th
                                                  width="10%"
                                                  className="d-none d-md-table-cell text-center"
                                                >
                                                  Date{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th width="40%">
                                                  Type / Title / Memo{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th width="15%" className="text-end">
                                                  Amount{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th
                                                  width="25%"
                                                  className="d-none d-md-none d-lg-table-cell"
                                                ></th>
                                              </tr>
                                              <tr className="display-none projected_table_action_container">
                                                <td colSpan="6" className="text-left">
                                                  <div className="batch-action-container">
                                                    <button className="btn btn-trash">
                                                      <i className="bi bi-trash-fill" /> Move to Trash
                                                    </button>
                                                    <button
                                                      className="btn medium_purple"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Merge Duplicate Records"
                                                    >
                                                      <i className="bi bi-share-fill flip-horizontal" /> Merge
                                                    </button>
                                                    <button
                                                      className="btn btn-success"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Nest related records into a single row to keep things organized."
                                                    >
                                                      <i className="bi bi-bezier" /> Nest
                                                    </button>
                                                    <button
                                                      className="btn btn-default"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Un-Nest"
                                                    >
                                                      <i className="bi bi-bezier2" /> Un-Nest
                                                    </button>
                                                    <button
                                                      className="btn btn-secondary"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Tags"
                                                    >
                                                      <i className="bi bi-tags-fill" /> Tags
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr className="display-none projected_table_action_container filter_table_action_container">
                                                <td colSpan="6" className="text-left">
                                                  <div className="batch-action-container">
                                                    <div className="row no-gutters">
                                                      <div className="col-lg-12">
                                                        <div className="form-inline mb-3">
                                                          <label className="mb-0 mr-2">By Type</label>
                                                          <select className="form-select mb-0">
                                                            <option value="Image">Image</option>
                                                            <option value="Audio">Audio</option>
                                                            <option value="Video">Video</option>
                                                            <option value="Archive">Archive</option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="row no-gutters">
                                                      <div className="col-lg-9 col-md-9 col-sm-12">
                                                        <div className="form-inline mb-2">
                                                          <h4>
                                                            <i className="bi bi-funnel-fill" /> Show me
                                                            <select className="form-select mb-0 mx-2">
                                                              <option value="Contacts">Contacts</option>
                                                              <option value="Files_Workrooms">
                                                                Files & Workrooms
                                                              </option>
                                                              <option value="Accounting_Records">
                                                                Accounting Records
                                                              </option>
                                                            </select>
                                                            that are…
                                                          </h4>
                                                        </div>
                                                        <div className="mb-3">
                                                          <select className="form-select mb-3">
                                                            <option value="Tagged_with…">Tagged with…</option>
                                                            <option value="Selling_Opportunities">
                                                              Selling Opportunities
                                                            </option>
                                                          </select>
                                                          <button
                                                            type="button"
                                                            className="btnAddNew"
                                                            id="btnAddNew"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Add New"
                                                          ></button>
                                                        </div>
                                                        <div className="mb-3">
                                                          <label>& Add a Custom Column</label>
                                                          <input
                                                            type="text"
                                                            name="addCustomColumn"
                                                            placeholder="tag-score"
                                                            className="form-control"
                                                          />
                                                        </div>
                                                        <div className="mb-3">
                                                          <label>& then sort the records by…</label>
                                                          <input
                                                            type="text"
                                                            name="sortRecordsBy"
                                                            placeholder="tag-score"
                                                            className="form-control"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                                                        <h4>
                                                          <i className="bi bi-bullseye" /> Saved Filtered
                                                          Views
                                                        </h4>
                                                        <ul>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Helpdesk Tasks
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Homebuyer Selling Opps
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Open Client Projects
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              UserSavedFilterLabelHere
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID12"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID12"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_green">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Projected"
                                                  >
                                                    <i className="bi bi-clock" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  06/08/2017
                                                </td>
                                                <td className="col_to_from">
                                                  Invoice #002 from{" "}
                                                  <a className="user_search" data-val="Tejas">
                                                    Tejas
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for week ending 12 June
                                                </td>
                                                <td className="text-end">
                                                  <button
                                                    className="btn btn-pay"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Click to Pay Now"
                                                  >
                                                    <strong>
                                                      <i className="bi bi-box-arrow-right" /> 75.00
                                                    </strong>
                                                  </button>
                                                </td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-colAction btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID1"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID1"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_blue">
                                                      <i className="bi bi-arrow-clockwise" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="PayPal Main"
                                                  >
                                                    <i className="bi bi-paypal" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  02/13/2017
                                                </td>
                                                <td className="col_to_from">
                                                  Subscription Payment From{" "}
                                                  <a className="user_search" data-val="lidia">
                                                    Lidia
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for SSL
                                                </td>
                                                <td className="text-end">194.30</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-colAction btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID2"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID2"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_green">
                                                      <i className="bi bi-box-arrow-in-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="PayPal Main"
                                                  >
                                                    <i className="bi bi-file-earmark-text" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  01/20/2017
                                                </td>
                                                <td className="col_to_from">
                                                  Invoice To{" "}
                                                  <a className="user_search" data-val="Aishwarya Rai">
                                                    Aishwarya Rai
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for Hosting through...{" "}
                                                  <div className="colTags">
                                                    <a href="javascript:;" data-val="hosting">
                                                      #hosting
                                                    </a>
                                                  </div>
                                                </td>
                                                <td className="text-end">291.30</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID3"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID3"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_red">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Chase Business"
                                                  >
                                                    <i className="bi bi-bank2" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  06/02/2016
                                                </td>
                                                <td>
                                                  Payment To Bank Wire{" "}
                                                  <div className="colTags">
                                                    <a href="javascript:;" data-val="06/02/2016">
                                                      #06/02/2016
                                                    </a>
                                                  </div>
                                                </td>
                                                <td className="text-end">(-123.00)</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-colAction btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID4"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID4"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_red">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="PayPal Main"
                                                  >
                                                    <i className="bi bi-paypal" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  06/24/2015
                                                </td>
                                                <td>Payment To Tonys lidia showbiz ded</td>
                                                <td className="text-end">(-39.21)</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-colAction btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID5"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID5"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_blue">
                                                      <i className="bi bi-arrow-clockwise" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Cash"
                                                  >
                                                    <i className="bi bi-currency-dollar" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  04/27/2015
                                                </td>
                                                <td className="col_to_from">
                                                  Subscription Payment To{" "}
                                                  <a className="user_search" data-val="CheapSSL.com">
                                                    CheapSSL.com
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for Test - running balance
                                                </td>
                                                <td className="text-end">(-10.50)</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-chat btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-users btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID6"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID6"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_red">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="PayPal Main"
                                                  >
                                                    <i className="bi bi-paypal" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  01/31/2017
                                                </td>
                                                <td className="col_to_from">
                                                  Payment To{" "}
                                                  <a className="user_search" data-val="Tejas">
                                                    Tejas
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for Test
                                                </td>
                                                <td className="text-end">(-100.00)</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID7"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID7"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_green">
                                                      <i className="bi bi-box-arrow-in-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="PayPal Main"
                                                  >
                                                    <i className="bi bi-file-earmark-text" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  05/27/2017
                                                </td>
                                                <td className="col_to_from">
                                                  Invoice To{" "}
                                                  <a className="user_search" data-val="Tejas">
                                                    Tejas
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for Test
                                                </td>
                                                <td className="text-end">100.00</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="P_ID8"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="P_ID8"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status bg_red">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  <span
                                                    className="btn btn-status"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="PayPal Main"
                                                  >
                                                    <i className="bi bi-paypal" />
                                                  </span>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  04/08/2017
                                                </td>
                                                <td className="col_to_from">
                                                  Payment To{" "}
                                                  <a className="user_search" data-val="Samier Sompura">
                                                    Samier Sompura
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>{" "}
                                                  for Test
                                                </td>
                                                <td className="text-end">(-100.00)</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">1</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div
                                          className="tab-pane fade"
                                          id="agree-subscription"
                                          role="tabpanel"
                                          aria-labelledby="agree-subscription-tab"
                                        >
                                          <table
                                            id="agreements_subscriptions_table"
                                            className="table table-striped customCheckbox table-head-color dataTable no-footer"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <thead>
                                              <tr>
                                                <th width="5%">
                                                  <a
                                                    tabindex="0"
                                                    className="agreements_subscriptions_table_action table-head-icon"
                                                  >
                                                    <i className="bi bi-gear-fill" />
                                                  </a>
                                                </th>
                                                <th
                                                  width="10%"
                                                  className="d-none d-md-table-cell text-center"
                                                >
                                                  Renewal Date
                                                </th>
                                                <th width="40%">
                                                  Product{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th width="15%" className="d-none d-md-table-cell">
                                                  Type{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th width="10%" className="text-end">
                                                  Amount{" "}
                                                  <a
                                                    href="javascript:;"
                                                    className="table_filter"
                                                    onclick="event.stopPropagation();"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Custom Column View"
                                                  >
                                                    <i className="bi bi-plus-lg-square" />
                                                  </a>
                                                </th>
                                                <th
                                                  width="20%"
                                                  className="d-none d-md-none d-lg-table-cell"
                                                ></th>
                                              </tr>
                                              <tr className="display-none agreements_subscriptions_table_action_container">
                                                <td colSpan="6" className="text-left">
                                                  <div className="batch-action-container">
                                                    <button className="btn btn-trash">
                                                      <i className="bi bi-trash-fill" /> Move to Trash
                                                    </button>
                                                    <button
                                                      className="btn medium_purple"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Merge Duplicate Records"
                                                    >
                                                      <i className="bi bi-share-fill flip-horizontal" /> Merge
                                                    </button>
                                                    <button
                                                      className="btn btn-success"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Nest related records into a single row to keep things organized."
                                                    >
                                                      <i className="bi bi-bezier" /> Nest
                                                    </button>
                                                    <button
                                                      className="btn btn-default"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Un-Nest"
                                                    >
                                                      <i className="bi bi-bezier2" /> Un-Nest
                                                    </button>
                                                    <button
                                                      className="btn btn-secondary"
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-animation="false"
                                                      title="Tags"
                                                    >
                                                      <i className="bi bi-tags-fill" /> Tags
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr className="display-none agreements_subscriptions_table_action_container filter_table_action_container">
                                                <td colSpan="6" className="text-left">
                                                  <div className="batch-action-container">
                                                    <div className="row no-gutters">
                                                      <div className="col-lg-12">
                                                        <div className="form-inline mb-3">
                                                          <label className="mb-0 mr-2">By Type</label>
                                                          <select className="form-select mb-0">
                                                            <option value="Image">Image</option>
                                                            <option value="Audio">Audio</option>
                                                            <option value="Video">Video</option>
                                                            <option value="Archive">Archive</option>
                                                          </select>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="row no-gutters">
                                                      <div className="col-lg-8 col-sm-12">
                                                        <div className="form-inline mb-2">
                                                          <h4>
                                                            <i className="bi bi-funnel-fill" /> Show me
                                                            <select className="form-select mb-0 mx-2">
                                                              <option value="Contacts">Contacts</option>
                                                              <option value="Files_Workrooms">
                                                                Files & Workrooms
                                                              </option>
                                                              <option value="Accounting_Records">
                                                                Accounting Records
                                                              </option>
                                                            </select>
                                                            that are…
                                                          </h4>
                                                        </div>
                                                        <div className="mb-3">
                                                          <select className="form-select mb-3">
                                                            <option value="Tagged_with…">Tagged with…</option>
                                                            <option value="Selling_Opportunities">
                                                              Selling Opportunities
                                                            </option>
                                                          </select>
                                                          <button
                                                            type="button"
                                                            className="btnAddNew"
                                                            id="btnAddNew"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Add New"
                                                          ></button>
                                                        </div>
                                                        <div className="mb-3">
                                                          <label>& Add a Custom Column</label>
                                                          <input
                                                            type="text"
                                                            name="addCustomColumn"
                                                            placeholder="tag-score"
                                                            className="form-control"
                                                          />
                                                        </div>
                                                        <div className="mb-3">
                                                          <label>& then sort the records by…</label>
                                                          <input
                                                            type="text"
                                                            name="sortRecordsBy"
                                                            placeholder="tag-score"
                                                            className="form-control"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-lg-1"></div>
                                                      <div className="col-lg-3 col-md-3 col-sm-12 savedFilterdView">
                                                        <h4>
                                                          <i className="bi bi-bullseye" /> Saved Filtered
                                                          Views
                                                        </h4>
                                                        <ul>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Helpdesk Tasks
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Homebuyer Selling Opps
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              Open Client Projects
                                                            </a>
                                                          </li>
                                                          <li>
                                                            <a
                                                              href="LINKTOTHIS_SAVED_CUSTOM_VIEW"
                                                              className="btn btn-default"
                                                            >
                                                              UserSavedFilterLabelHere
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr className="neutral_row">
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="AS_ID1"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="AS_ID1"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan account_status">
                                                      <i className="bi bi-box-arrow-right" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td className="text-center d-none d-md-table-cell">
                                                  06/08/2017
                                                </td>
                                                <td>
                                                  Electronic Signature Business Package - Monthly From{" "}
                                                  <a className="user_search" data-val="Amitabh Bachchan">
                                                    Amitabh Bachchan
                                                  </a>{" "}
                                                  <a
                                                    href="http://LINKTOTHISCONTACTRECORDGOESHERE"
                                                    className="user_link"
                                                    target="_blank"
                                                  >
                                                    <i className="bi bi-person-fill" />
                                                  </a>
                                                </td>
                                                <td className="d-none d-md-table-cell">Subscription</td>
                                                <td className="text-end">75/mo</td>
                                                <td className="text-end colAction d-none d-lg-block">
                                                  <a
                                                    className="btn btn-colAction btn-chat"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Comments"
                                                  >
                                                    <i className="bi bi-chat-fill" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-secondary btn-colAction btn-users"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Attachments"
                                                  >
                                                    <i className="bi bi-paperclip" />
                                                    <span className="badge badge-danger">0</span>
                                                  </a>
                                                  <a
                                                    className="btn btn-trash btn-colAction"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-animation="false"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mb-3" id="files_panel">
                            <div className="accordion-item panel panel-beige">
                              <div
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target="#files_container"
                                aria-expanded="false"
                                aria-controls="files_container"
                              >
                                <i className="bi bi-paperclip me-2" /> Files
                              </div>
                              <div
                                id="files_container"
                                className="accordion-collapse collapse"
                                aria-labelledby="files_container"
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="float-end">
                                        <ul
                                          className="nav nav-tabs swiftCloudTabs d-none d-md-flex"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <button type="button" className="btn btn-secondary btnUpload">
                                              <i className="bi bi-cloud-arrow-up-fill" />{" "}
                                              <span className="d-none d-md-inline">Upload</span>
                                            </button>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link active"
                                              id="drive-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#drive"
                                              role="tab"
                                              aria-controls="drive"
                                              aria-selected="false"
                                            >
                                              <span data-bs-toggle="tooltip" data-bs-title="Drive">
                                                <i className="bi bi-database-fill" />
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link"
                                              id="small-icon-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#small-icon"
                                              role="tab"
                                              aria-controls="small-icon"
                                              aria-selected="false"
                                            >
                                              <span data-bs-toggle="tooltip" data-bs-title="Small Icons">
                                                <i className="bi bi-ui-checks-grid" />
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item" role="presentation">
                                            <a
                                              className="nav-link"
                                              id="large-icon-tab"
                                              data-bs-toggle="tab"
                                              data-bs-target="#large-icon"
                                              role="tab"
                                              aria-controls="large-icon"
                                              aria-selected="false"
                                            >
                                              <span data-bs-toggle="tooltip" data-bs-title="Large Icons">
                                                <i className="bi bi-stop-fill" />
                                              </span>
                                            </a>
                                          </li>
                                          <li className="nav-item nav-item-trash" role="presentation">
                                            <a
                                              className="nav-link"
                                              data-bs-toggle="tooltip"
                                              data-placement="top"
                                              title="View Templates"
                                            >
                                              <i className="bi bi-journals" />
                                            </a>
                                          </li>
                                          <li className="nav-item nav-item-trash" role="presentation">
                                            <a
                                              className="nav-link"
                                              data-bs-toggle="tooltip"
                                              data-placement="top"
                                              title="Trash"
                                            >
                                              <i className="bi bi-trash-fill" />
                                            </a>
                                          </li>
                                        </ul>

                                        <ul
                                          className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1"
                                          role="tablist"
                                        >
                                          <li className="nav-item">
                                            <button
                                              type="button"
                                              className="btn btn-secondary btnUpload me-1"
                                            >
                                              <i className="bi bi-cloud-arrow-up-fill" />
                                            </button>
                                          </li>
                                          <li className="nav-item">
                                            <div className="btn-group btnDriveView" role="group">
                                              <button
                                                id="btnGroupDrop3"
                                                type="button"
                                                className="btn btn-default dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="bi bi-database-fill" />
                                              </button>
                                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    id="drive-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#drive"
                                                    role="tab"
                                                    aria-controls="drive"
                                                    aria-selected="false"
                                                  >
                                                    <i className="bi bi-database-fill" /> Drive
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    id="small-icon-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#small-icon"
                                                    role="tab"
                                                    aria-controls="small-icon"
                                                    aria-selected="false"
                                                  >
                                                    <i className="bi bi-ui-checks-grid" /> Small Icons
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    id="large-icon-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#large-icon"
                                                    role="tab"
                                                    aria-controls="large-icon"
                                                    aria-selected="false"
                                                  >
                                                    <i className="bi bi-stop-fill" /> Large Icons
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="#">
                                                    <i className="bi bi-journals" /> Templates
                                                  </a>
                                                </li>
                                                <li>
                                                  <a className="dropdown-item" href="#">
                                                    <i className="bi bi-trash-fill" /> Trash
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="tab-content autoAdjustSearchBox" id="myTabContent">
                                        <div
                                          className="tab-pane fade show active"
                                          id="drive"
                                          role="tabpanel"
                                          aria-labelledby="drive-tab"
                                        >
                                          <table
                                            id="tblDrive"
                                            className="table dataTable"
                                            cellspacing="0"
                                            width="100%"
                                          >
                                            <thead>
                                              <tr>
                                                <th width="50">&nbsp;</th>
                                                <th width="60%">
                                                  Name/Label{" "}
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btnTHSoryByTags float-end me-3"
                                                    onclick="event.stopPropagation();"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#ContactsSortByTagModal"
                                                    title="Advanced Tag Search"
                                                  >
                                                    <span
                                                      data-bs-toggle="tooltip"
                                                      title="Advanced Tag Search"
                                                    >
                                                      <i className="bi bi-tag-fill" />
                                                    </span>
                                                  </button>
                                                </th>
                                                <th width="18%" className="d-none d-md-table-cell">
                                                  Last Activity
                                                </th>
                                                <th
                                                  width="7%"
                                                  className="text-end d-none d-lg-table-cell"
                                                ></th>
                                              </tr>
                                              <tr className="d-none table_action_container">
                                                <td colSpan="4" className="text-left">
                                                  <div className="batch-action-container">
                                                    <button className="btn btn-trash my-1">
                                                      <i className="bi bi-trash-fill" /> Move to Trash
                                                    </button>
                                                    <button
                                                      className="btn btn-default my-1"
                                                      data-bs-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Archive (Searchable but Hidden)"
                                                    >
                                                      <i className="bi bi-file-earmark-zip-fill" /> Archive
                                                    </button>
                                                    <button className="btn btn-secondary my-1">
                                                      <i className="bi bi-tag-fills" /> Apply Tag(s)
                                                    </button>
                                                    <button className="btn btn-success my-1">
                                                      <i className="bi bi-person-plus-fill" /> Manage Sharing
                                                    </button>
                                                    <button className="btn btn-primary my-1">
                                                      <i className="bi bi-unlock-fill" /> Manage Permissions
                                                    </button>
                                                    <button className="btn btn-secondary my-1">
                                                      <i className="bi bi-arrows-move" /> Move To...
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="C_ID9"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="C_ID9"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan btn-secondary">
                                                      <i className="bi bi-three-dots" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <a href="#">SequenceNameHere</a>
                                                  <a
                                                    href="LINK_TO_SOCIALIZER_POPUP"
                                                    className="btnSocializer"
                                                    data-bs-toggle="tooltip"
                                                    title="Share / Attach to Contact"
                                                  >
                                                    <i className="bi bi-person-plus-fill" />
                                                  </a>
                                                </td>
                                                <td className="d-none d-md-table-cell">
                                                  <time className="timeago" datetime="2022-05-15"></time>
                                                </td>
                                                <td className="text-end tableColAction d-none d-lg-table-cell">
                                                  <a
                                                    className="btn btn-delete"
                                                    data-bs-toggle="tooltip"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="text-center tableColChkDel">
                                                  <div className="tableColChkDelBox">
                                                    <input
                                                      type="checkbox"
                                                      name="fileid[]"
                                                      id="C_ID10"
                                                      className="css-checkbox"
                                                    />
                                                    <label
                                                      htmlFor="C_ID10"
                                                      className="css-checkbox-label"
                                                    ></label>
                                                    <span className="tableColChkSpan btn-secondary">
                                                      <i className="bi bi-link-45deg" />
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <a href="#">Tracking URL Here...</a>
                                                  <a
                                                    href="LINK_TO_SOCIALIZER_POPUP"
                                                    className="btnSocializer"
                                                    data-bs-toggle="tooltip"
                                                    title="Share / Attach to Contact"
                                                  >
                                                    <i className="bi bi-person-plus-fill" />
                                                  </a>
                                                </td>
                                                <td className="d-none d-md-table-cell">
                                                  <time className="timeago" datetime="2022-05-15"></time>
                                                </td>
                                                <td className="text-end tableColAction d-none d-lg-table-cell">
                                                  <a
                                                    className="btn btn-delete"
                                                    data-bs-toggle="tooltip"
                                                    title="Delete"
                                                  >
                                                    <i className="bi bi-trash-fill" />
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div
                                          className="tab-pane fade"
                                          id="small-icon"
                                          role="tabpanel"
                                          aria-labelledby="small-icon-tab"
                                        >
                                          <ul className="smallIconList">
                                            <li className="folderIcon">
                                              <a href="#">
                                                <span>
                                                  <i className="bi bi-folder-fill" />
                                                </span>
                                                <span className="fileName">Incoming</span>
                                              </a>
                                            </li>
                                            <li className="folderIcon">
                                              <a href="#">
                                                <span>
                                                  <i className="bi bi-folder-fill" />
                                                </span>
                                                <span className="fileName">Tasks and Projects</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-file-earmark" />
                                                </span>
                                                <span className="fileName">eDoc</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-table" />
                                                </span>
                                                <span className="fileName">Spreadsheet</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-table" />
                                                </span>
                                                <span className="fileName">Invoice</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-card-checklist" />
                                                </span>
                                                <span className="fileName">Web form</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-envelope-fill" />
                                                </span>
                                                <span className="fileName">Envelope</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-people-fill" />
                                                </span>
                                                <span className="fileName">Workroom</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-cart-fill" />
                                                </span>
                                                <span className="fileName">Item you sell</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-three-dots" />
                                                </span>
                                                <span className="fileName">Sequence</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-calendar" />
                                                </span>
                                                <span className="fileName">Scheduler</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-journals" />
                                                </span>
                                                <span className="fileName">Template</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-telephone-fill" />
                                                </span>
                                                <span className="fileName">Phone number</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span className="btn-secondary fileTypeIcon">
                                                  <i className="bi bi-box-arrow-in-right" />
                                                </span>
                                                <span className="fileName">Paywall</span>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="#">
                                                <span
                                                  className="fileTypeIcon"
                                                  style={{
                                                    background:
                                                      "url(images/pedro.jpg) no-repeat center / cover",
                                                  }}
                                                />
                                                <span className="fileName">Doc with featured image</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div
                                          className="tab-pane fade"
                                          id="large-icon"
                                          role="tabpanel"
                                          aria-labelledby="large-icon-tab"
                                        >
                                          <ul className="largeIconList">
                                            <li className="folderIcon">
                                              <a href="#">
                                                <span>
                                                  <i className="bi bi-folder-fill" />
                                                </span>
                                                <span className="fileName">Incoming</span>
                                              </a>
                                            </li>
                                            <li className="folderIcon">
                                              <a href="#">
                                                <span>
                                                  <i className="bi bi-folder-fill" />
                                                </span>
                                                <span className="fileName">Tasks and Projects</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-file-earmark" />
                                                </span>
                                                <span className="fileName">eDoc</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-table" />
                                                </span>
                                                <span className="fileName">Spreadsheet</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-table" />
                                                </span>
                                                <span className="fileName">Invoice</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-card-checklist" />
                                                </span>
                                                <span className="fileName">Web form</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-envelope-fill" />
                                                </span>
                                                <span className="fileName">Envelope</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-people-fill" />
                                                </span>
                                                <span className="fileName">Workroom</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-cart-fill" />
                                                </span>
                                                <span className="fileName">Item you sell</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-three-dots" />
                                                </span>
                                                <span className="fileName">Sequence</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-calendar" />
                                                </span>
                                                <span className="fileName">Scheduler</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-journals" />
                                                </span>
                                                <span className="fileName">Template</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-telephone-fill" />
                                                </span>
                                                <span className="fileName">Phone number</span>
                                              </a>
                                            </li>
                                            <li className="btn-secondary">
                                              <a href="#">
                                                <span className="fileTypeIcon">
                                                  <i className="bi bi-box-arrow-in-right" />
                                                </span>
                                                <span className="fileName">Paywall</span>
                                              </a>
                                            </li>
                                            <li
                                              className="btn-secondary"
                                              style={{
                                                background: "url(images/pedro.jpg) no-repeat center / cover",
                                              }}
                                            >
                                              <a href="#">
                                                <span className="fileName">Doc with featured image</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <MorePanel
                            entityId={campaign.id}
                            campaign={campaign}
                            tags={tags}
                            setTags={setTags}
                            tempTags={tempTags}
                            setTempTags={setTempTags}
                          />

                          <div className="col-lg-12 text-center">
                            <button type="submit" className="btn btn-primary btn-lg">
                              <i className="bi bi-check2" /> Save Changes
                            </button>
                            <button type="button" className="btn btnGreenSend btn-lg">
                              <i className="bi bi-play-fill" /> Resume Campaign{" "}
                              <i className="bi bi-megaphone-fill" />
                            </button>
                            <button className="btn btn-magic btn-lg" onClick={() => setHistoryBlock(true)}>
                              <i className="bi bi-clock-history" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {historyBlock && (
                    <HistoryNote
                      campaign={campaign}
                      historyBlock={historyBlock}
                      setHistoryBlock={setHistoryBlock}
                      historyNotes={historyNotes}
                      setHistoryNotes={setHistoryNotes}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    </Layout>
  )
}

export default Detail
