import * as React from "react"
import Form from "react-bootstrap/Form"
import Moment from "react-moment"
import { useEffect, useState } from "react"
import AxiosInstance from "../../../../services/axiosInstance"

const HistoryNote = ({ campaign, historyBlock, setHistoryBlock, historyNotes, setHistoryNotes }) => {
  const headerContainerHeight = 5,
    [closeHistoryBlock, setCloseHistoryBlock] = useState(false),
    [historyType, setHistoryType] = useState("private_note"),
    [chatBlockHeight, setChatBlockHeight] = useState(window.innerHeight),
    [chatBlockTop, setChatBlockTop] = useState(headerContainerHeight),
    [historyBlockHeight, setHistoryBlockHeight] = useState(window.innerHeight),
    [updateHistoryBlock, setUpdateHistoryBlock] = useState(false),
    [notesTextArea, setNotesTextArea] = useState(""),
    [notesTextAreaError, setNotesTextAreaError] = useState(""),
    [addNoteArea, setAddNoteArea] = useState(true),
    [generateReport, setGenerateReport] = useState(false)

  const addNotes = () => {
    if (notesTextArea && campaign.id) {
      setNotesTextAreaError("")

      return new Promise(async () => {
        await AxiosInstance.post(`/history-note/add`, {
          campaignId: campaign.id,
          content: notesTextArea,
          mode: "public",
          type: "note",
          status: true,
        })
          .then(function (response) {
            if (response.status === 201) {
              setUpdateHistoryBlock(true)
              setNotesTextArea("")
            }
          })
          .catch(function (error) {
            if (error.response.status === 400) {
              setNotesTextAreaError(error.response.data.message)
            } else {
              setNotesTextAreaError(error.response.data.detail)
            }
          })
      })
    }
  }

  const getHistoryNotes = () => {
    return new Promise(async () => {
      await AxiosInstance.get(`/campaign/history-note/${campaign.id}`)
        .then(function (response) {
          setHistoryNotes(response.data)
          return true
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setNotesTextAreaError(error.response.data.message)
          } else {
            setNotesTextAreaError(error.response.data.detail)
          }
        })
    })
  }

  const updateHistoryBlockHeight = () => {
    if (
      document &&
      document.querySelector(".ChatDockHeader") &&
      document.querySelector(".ChatDockFooterContainer")
    ) {
      setChatBlockHeight(window.innerHeight - headerContainerHeight)
      setChatBlockTop(headerContainerHeight)
      setHistoryBlockHeight(
        window.innerHeight -
          headerContainerHeight -
          document.querySelector(".ChatDockHeader").clientHeight -
          document.querySelector(".ChatDockFooterContainer").clientHeight
      )
    }
  }

  const showAddNote = () => {
    setAddNoteArea(true)
    setGenerateReport(false)
  }

  const showGenerateReport = () => {
    setAddNoteArea(false)
    setGenerateReport(true)
  }

  useEffect(() => {
    if (updateHistoryBlock) {
      setUpdateHistoryBlock(false)
      if (campaign.id) {
        getHistoryNotes()
      }
    }

    let updateHistoryBlockHeightInterval = setInterval(() => {
      updateHistoryBlockHeight()
      clearInterval(updateHistoryBlockHeightInterval)
    }, 500)

    if (closeHistoryBlock) {
      setCloseHistoryBlock(false)
      setHistoryBlock(false)
    }
  })

  return (
    <div className="col-lg-6">
      <div className="chatDockSwitch">
        <input type="checkbox" id="chatDockSwitch" />
        <label htmlFor="chatDockSwitch" className="checkmark" />
      </div>
      <div className="SCChatDockNonSocialAssetOnly">
        <div className="ChatDockHeader">
          <div className="row">
            <div className="col-lg-4 col-md-8">
              <Form.Select
                className="ChatDockInputOpt"
                data-width="200"
                onChange={e => {
                  console.log(e.target.value)
                  if (e.target.value === "report") {
                    showGenerateReport()
                  } else {
                    showAddNote()
                  }
                }}
              >
                <option value="history" data-icon="fa-history">
                  History
                </option>
                <option value="Private_note" data-icon="fa-sticky-note" selected>
                  Add Note
                </option>
                <option value="report" data-icon="fa-bar-chart">
                  Generate Report
                </option>
              </Form.Select>
            </div>

            <div className="col-lg-2 col-md-2">
              <a
                className="btnCloseChatDock"
                data-bs-toggle="tooltip"
                data-placement="left"
                data-animation="false"
                title="Close"
                onClick={() => {
                  setCloseHistoryBlock(true)
                }}
              >
                <i className="bi bi-x-lg" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="ChatDockHistoryContainer"
          style={{
            position: "relative",
            top: "0",
            left: "0",
            overflow: "scroll",
            height: historyBlockHeight,
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <ul className="chat">
            {historyNotes !== null &&
              historyNotes.length > 0 &&
              historyNotes.map((historyData, historyIndex) => {
                let chatClass = historyData.messageSent ? "self" : "other"
                let imagePath = historyData.messageSent ? "swift_cloud_flag.jpg" : "roger.jpg"

                return (
                  <li className="other">
                    <div className="chat-msg">
                      <div className="avatar">
                        <a
                          href="#"
                          className="quickcontact_userSelect d-block"
                          data-bs-toggle="tooltip"
                          data-placement="bottom"
                          data-animation="false"
                          title="Amitabh Bachchan"
                        >
                          <img src={process.env.GATSBY_WRANGLER_URL + "StaticImages/" + imagePath} alt="" />
                        </a>
                      </div>
                      <div className="msg">
                        <p>
                          <strong>{historyData.title}</strong>
                        </p>
                        <p>{historyData.content}</p>
                        <span className="msg-by-time">
                          {historyData.mode} Note by&nbsp; {campaign.name} at&nbsp;
                          <Moment format="hh:mm a on dddd, MMM DD, YYYY">{historyData.updatedAt}</Moment>
                        </span>
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>

        <div className="ChatDockFooterContainer">
          {addNoteArea && (
            <div className="ChatDockOpt ChatDockPrivateNoteOpt">
              <div className="mb-2">
                <Form>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add Note"
                    value={notesTextArea}
                    defaultValue={notesTextArea}
                    onChange={event => {
                      setNotesTextArea(event.target.value)
                    }}
                    onMouseLeave={event => {
                      setNotesTextArea(event.target.value)
                    }}
                  />
                  {notesTextAreaError && <div className="error-text">{notesTextAreaError}</div>}
                </Form>
              </div>
              <div className="row">
                <div className="col-lg-6 text-start ChatDockPrivateNoteTools">
                  <a
                    data-bs-toggle="modal"
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Add URL"
                    aria-label="Add URL"
                  >
                    <i className="bi bi-link-45deg" />
                  </a>
                  <a
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Insert Image/Video"
                    aria-label="Insert Image/Video"
                  >
                    <i className="bi bi-camera-fill" />
                  </a>
                  <a
                    className="chatNoteImg"
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Upload file"
                    aria-label="Upload file"
                  >
                    <i className="bi bi-paperclip" />
                  </a>
                  <a
                    data-toggle="tooltip"
                    title=""
                    data-bs-original-title="Inject Emoji"
                    aria-label="Inject Emoji"
                  >
                    <i className="bi bi-emoji-smile" />
                  </a>
                  <input
                    type="file"
                    accept="image/*"
                    className="chatNoteImgFile"
                    style={{ visibility: "hidden", position: "absolute" }}
                  />
                </div>
                <div className="col-lg-6 text-end">
                  <button type="button" className="btn btn-primary" onClick={addNotes}>
                    <i className="bi bi-check2" />
                    Add Note
                  </button>
                </div>
              </div>
            </div>
          )}

          {generateReport && (
            <div className="ChatDockOpt ChatDockReportOpt">
              <div className="text-center py-3">
                <button type="button" className="btn btn-primary">
                  <i className="bi bi-bar-chart-line-fill" />
                  Generate Report
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HistoryNote
