import * as React from "react"
import { useState } from "react"
import clsx from "clsx"

import ContactListRow from "./contactListRow"
import ContactListTagSortModal from "./contactListTagSortModal"
import AxiosInstance from "../../../services/axiosInstance"
import MoveToModal from "./moveToModal"

import "../../../css/jquery.dataTables.min.css"
import "../contacts.css"

const ContactTable = ({ data, groupId, campaignId, setDeleteContactRow, setIsEscalateContact }) => {
  groupId = groupId === undefined ? null : groupId
  campaignId = campaignId === undefined ? null : campaignId

  const [moveTo, setMoveTo] = React.useState(false)
  const [selectedContacts, setSelectedContacts] = useState([])
  const [groupList, setGroupList] = React.useState([])

  const getGroupList = () => {
    AxiosInstance.get(`/contact/group`).then(response => {
      setGroupList(response.data.items)
    })
  }

  React.useEffect(() => {
    getGroupList()
  }, [])

  return (
    <React.Fragment>
      <table id="tblContacts" className="table dataTable" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th width="5%">&nbsp;</th>
            <th width={`${groupId ? "50%" : "80%"}`}>
              {!groupId && !campaignId && (
                <React.Fragment>
                  <div className="btn-group btnAddNewDropdown filterContactDropdown" role="group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-bullseye" />
                      <span className="d-none d-md-inline"> Everyone</span>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-people-fill" /> Local Friends
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-person-fill" /> People
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-building" /> Companies
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-person-check-fill" /> Following
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-people-fill" /> Tribes
                        </a>
                      </li>
                    </ul>
                  </div>
                  <ContactListTagSortModal />
                </React.Fragment>
              )}
            </th>
            {groupId && (
              <th width="5%" className="d-none d-md-table-cell">
                <i className="bi bi-trophy-fill" data-bs-toggle="tooltip" title="Average Rave Score" />
              </th>
            )}
            {campaignId && (
              <th width="5%" className="d-none d-md-table-cell">
                <i className="bi bi-person-circle" title="Rave Score" aria-label="Rave Score" />
              </th>
            )}
            <th width="15%" className="text-end d-none d-lg-table-cell">
              Last Activity
            </th>
          </tr>
          {selectedContacts?.length > 0 && (
            <tr className="table_action_container">
              <td colSpan="5" className="text-left">
                <div className="batch-action-container">
                  {/*<button className="btn btn-trash">
                    <i className="bi bi-trash-fill" /> Move to Trash
                  </button>{" "}*/}
                  <button type="button" className="btn btn-secondary" onClick={() => setMoveTo(true)}>
                    <i className="bi bi-arrows-move" /> Move To...
                  </button>
                </div>
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {data.map((contact, index) => {
            return (
              <ContactListRow
                contact={contact}
                key={`contact_row_${index}`}
                groupId={groupId}
                campaignId={campaignId}
                setDeleteContactRow={setDeleteContactRow}
                setIsEscalateContact={setIsEscalateContact}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
              />
            )
          })}
        </tbody>
      </table>
      <MoveToModal
        moveTo={moveTo}
        currentGroupId={groupId}
        setMoveTo={setMoveTo}
        groupList={groupList}
        selectedContacts={selectedContacts}
      />
    </React.Fragment>
  )
}

export default ContactTable
