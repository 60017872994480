export const DATA_IS_FOR_CONTACT = "contact"
export const DATA_IS_FOR_COMPANY = "company"

export const TYPE_CONTACT_LIST = "contacts"
export const TYPE_DATA_ONLY = "dataOnly"
export const TYPE_FILTER_VIEW = "filterView"

export const PERMISSION_NONE = "none"
export const PERMISSION_OPT_OUT = "opt-out"
export const PERMISSION_SOCIAL = "social_mode"

export const PRIVACY_PUBLIC = "public"
export const PRIVACY_PRIVATE = "private"
export const PRIVACY_MEMBERS = "members_only"

export const ROLE_TYPE_IRRELEVANT = "role_are_irrelevant"
export const ROLE_TYPE_SAME = "same_role_for_all_members"
export const ROLE_TYPE_EXCLUSIVE = "exclusive_role_for_each_members"

export const ALL_PERMISSIONS = [
  {
    value: PERMISSION_NONE,
    label: "None. Only I can edit.",
    icon: "bi bi-lock-fill",
  },
  {
    value: PERMISSION_OPT_OUT,
    label: "Opt-out Mode: People can withdraw anytime but not join.",
    icon: "bi-binoculars-fill",
  },
  {
    value: PERMISSION_SOCIAL,
    label: "Social Mode: People can join or withdraw anytime.",
    icon: "bi bi-globe",
  },
]

export const ALL_PRIVACY = [
  {
    value: PRIVACY_PRIVATE,
    label: "Private to me.",
    icon: "bi bi-lock-fill",
  },
  {
    value: PRIVACY_MEMBERS,
    label: "Visible to Logged-in Members, Not Public.",
    icon: "bi-binoculars-fill",
  },
  {
    value: PRIVACY_PUBLIC,
    label: "Visible to Public.",
    icon: "bi bi-globe",
  },
]

export const ALL_ROLE_TYPES = [
  {
    value: ROLE_TYPE_IRRELEVANT,
    label: "Roles are irrelevant.",
    options: false,
  },
  {
    value: ROLE_TYPE_EXCLUSIVE,
    label: "All group members have the same role.",
    options: true,
  },
  {
    value: ROLE_TYPE_SAME,
    label: `Roles are exclusive. 1 person per role{" "} <span data-bs-toggle="tooltip" title="We'll use your role-settings from your profile to manage this." > <i class="bi bi-question-circle-fill"></i> </span>`,
    options: false,
  },
]
