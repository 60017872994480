import * as React from "react"
import { useState } from "react"
import ContactTable from "../../Contacts/Listing/contactTable"

const Members = ({ campaignId }) => {
  const [membersList, setMembersList] = useState(true)
  const updateMembersList = () => {
    setMembersList(!membersList)
  }
  return (
    <div className="col-lg-12 mb-3" id="team_panel">
      <div className="accordion-item panel panel-green">
        <div
          className="accordion-button"
          data-bs-toggle="collapse"
          data-bs-target="#team_container"
          aria-expanded="true"
          aria-controls="team_container"
        >
          <i className="bi bi-person-fill me-2" /> CampaignNameHere Members
        </div>
        <div
          id="team_container"
          className="accordion-collapse collapse show"
          aria-labelledby="team_container"
        >
          <div className="accordion-body">
            <div className="row">
              <div className="col-lg-12">
                <ContactTable listApi={"/contact"} campaignId={campaignId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Members
