import { useEffect, useState } from "react"
import * as React from "react"
import { Link } from "gatsby"
import AxiosInstance from "../../../services/axiosInstance"

const CampaignTitleName = ({ campaignId, name, setName, tempName, setTempName }) => {
  const [updateName, setUpdateName] = useState(false)
  const [nameError, setNameError] = useState("")

  const saveEntityName = () => {
    if (campaignId) {
      if (tempName) {
        return new Promise(async () => {
          await AxiosInstance.post(`/campaign/update-name/${campaignId}`, {
            name: tempName,
          })
            .then(function (response) {
              setName(setName)
            })
            .catch(function (error) {})
        })
      } else {
        setNameError("Please add Valid Name")
      }
    }
  }

  useEffect(() => {
    if (updateName) {
      setUpdateName(false)
      if (name !== tempName) {
        saveEntityName()
      }
    }
  })

  return (
    <div className="pageTitle_Editor">
      <Link to={"/campaigns"} className="goBack">
        <i className="bi bi-arrow-90deg-left" />
      </Link>
      <input
        type="text"
        className="form-control"
        placeholder="Group Name"
        value={tempName}
        defaultValue={tempName}
        onChange={e => setTempName(e.target.value)}
        onBlur={e => setUpdateName(true)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setUpdateName(true)
          }
        }}
      />
      {nameError && <div className="error-text"> {nameError} </div>}
    </div>
  )
}

export default CampaignTitleName
