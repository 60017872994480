import * as React from "react"
import Tags from "../../Contacts/Common/tags"
import {
  ALL_PERMISSIONS,
  ALL_PRIVACY,
  ALL_ROLE_TYPES,
  DATA_IS_FOR_CONTACT,
  DATA_IS_FOR_COMPANY,
} from "../CampaignHelper"
import { Form } from "react-bootstrap"
import { useEffect, useState } from "react"

const MorePanel = ({ entityId, campaign, tags, setTags, tempTags, setTempTags }) => {
  const [loadData, setLoadData] = useState(true),
    [tempPermission, setTempPermission] = useState(""),
    [tempPrivacy, setTempPrivacy] = useState(""),
    [tempRoleType, setTempRoleType] = useState(""),
    [tempRole, setTempRole] = useState("")
  useEffect(() => {
    if (loadData) {
      setLoadData(false)
      setTempPermission(campaign.permission)
      setTempPrivacy(campaign.privacy)
      setTempRoleType(campaign.roleType)
      setTempRole(campaign.role)
    }
  })
  return (
    <div className="col-lg-12 mb-3" id="more_panel">
      <div className="accordion-item panel panel-grey">
        <div
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#more_container"
          aria-expanded="false"
          aria-controls="more_container"
        >
          <i className="bi bi-three-dots me-2" /> More Options
        </div>
        <div id="more_container" className="accordion-collapse collapse" aria-labelledby="more_container">
          <div className="accordion-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-lock-fill" /> Privacy
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      {ALL_PRIVACY.map((privacy, index) => {
                        let privacyOptionId = `campaign_privacy_${privacy.value}`
                        return (
                          <div className="mb-1" key={privacyOptionId}>
                            <Form.Control
                              type="radio"
                              name="campaignPrivacy"
                              id={privacyOptionId}
                              className="css-checkbox"
                              value={privacy.value}
                              checked={privacy.value === tempPrivacy ? "checked" : ""}
                              onClick={e => {
                                setTempPrivacy(privacy.value)
                              }}
                            />
                            <label htmlFor={privacyOptionId} className="css-radio-label">
                              <i className={privacy.icon} /> {privacy.label}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-gear-fill" /> Permissions
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      {ALL_PERMISSIONS.map((permission, index) => {
                        let permissionOptionId = `campaign_permission_${permission.value}`
                        return (
                          <div className="mb-1" key={permissionOptionId}>
                            <Form.Control
                              type="radio"
                              name="campaignPermissions"
                              id={permissionOptionId}
                              className="css-checkbox"
                              value={permission.value}
                              checked={permission.value === tempPermission ? "checked" : ""}
                              onClick={e => {
                                setTempPermission(permission.value)
                              }}
                            />
                            <label htmlFor={permissionOptionId} className="css-radio-label">
                              <i className={permission.icon} /> {permission.label}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-person-circle" /> This data is..
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      {campaign.dataIsFor === DATA_IS_FOR_CONTACT && (
                        <React.Fragment>
                          "Cold" contacts. I cannot prove Opt-in.
                          <a href="http://swiftcrm.com/support/email" target="_blank">
                            <i className="bi bi-question-circle-fill" />
                          </a>
                        </React.Fragment>
                      )}
                      {campaign.dataIsFor === DATA_IS_FOR_COMPANY && (
                        <React.Fragment>
                          My company newsletter. I can prove opt in.
                          <a href="http://swiftcrm.com/support/email" target="_blank">
                            <i className="bi bi-question-circle-fill" />
                          </a>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 assetPrivacyContainer">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-person-circle" /> Roles
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      {ALL_ROLE_TYPES.map((roleType, index) => {
                        let roleTypeOptionId = `campaign_role_type_${roleType.value}`
                        return (
                          <div className="mb-1">
                            <Form.Control
                              type="radio"
                              name="campaignRoleType"
                              id={roleTypeOptionId}
                              className="css-checkbox"
                              value={roleType.value}
                              checked={roleType.value === tempRoleType ? "checked" : ""}
                              onClick={e => {
                                setTempRoleType(roleType.value)
                              }}
                            />
                            <label
                              htmlFor={roleTypeOptionId}
                              className="css-radio-label"
                              dangerouslySetInnerHTML={{ __html: roleType.label }}
                            />

                            {roleType.options && (
                              <div>
                                <select className="form-select">
                                  <option>Role list here...</option>
                                </select>
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-tags-fill" /> Tags
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <Tags
                        entityId={entityId}
                        api={"/campaign"}
                        tags={tags}
                        setTags={setTags}
                        tempTags={tempTags}
                        setTempTags={setTempTags}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-file-image" /> Featured Image
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      <div className="folderIcon">
                        <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`} />
                      </div>
                      <button type="button" className="btn btn-default btnDocFormIconEdit">
                        <span data-bs-toggle="tooltip" data-placement="bottom" title="Edit folder image">
                          <i className="bi bi-pencil-fill" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="colHeading">
                      <strong>
                        <i className="bi bi-chat-fill" /> Comments
                      </strong>
                    </div>
                    <div className="dh-block-body">
                      Comments are
                      <div className="toggleSwitch">
                        <input type="checkbox" value="1" name="campaign_comments" id="campaign_comments" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MorePanel
