import React from "react"
import { Link } from "gatsby"
import { useState } from "react"

const ContactTaskDetail = ({ contactTaskData }) => {
  const [transactionTypeIcon, setTransactionTypeIcon] = useState("check-square"),
    [transactionTypeIconColor, setTransactionTypeIconColor] = useState("#fff"),
    [transactionTypeIconBgColor, setTransactionTypeIconBgColor] = useState("#1658b8")

  return (
    <div className="SwiftCloudWorkroomTags">
      <ul>
        {contactTaskData?.map((contactTaskValue, contactTaskIndex) => {
          return (
            <li key={contactTaskIndex}>
              <Link
                to={`/jobs/detail/${contactTaskValue.task.id}`}
                className="nav-link active goBack "
                id="PrioritizedListView-tab"
              >
                <span
                  className="SwiftCloudWorkroomTagIcon"
                  style={{
                    backgroundColor: contactTaskValue.task.transactionType?.iconBackgroundColor
                      ? contactTaskValue.task.transactionType?.iconBackgroundColor
                      : transactionTypeIconBgColor,
                    color: contactTaskValue.task.transactionType?.iconColor
                      ? contactTaskValue.task.transactionType?.iconColor
                      : transactionTypeIconColor,
                  }}
                >
                  <i
                    className={`bi bi-${
                      contactTaskValue.task.transactionType?.icon
                        ? contactTaskValue.task.transactionType?.icon
                        : transactionTypeIcon
                    }`}
                  />
                </span>{" "}
                <span className="SwiftCloudWorkroomTagName">
                  {contactTaskValue.task.title}{" "}
                  {contactTaskValue.settingRole?.role ? `(${contactTaskValue.settingRole?.role})` : ""}
                </span>
              </Link>
            </li>
          )
        })}
      </ul>

      <div className="addNewCustomerJourneyContainer" />
    </div>
  )
}

export default ContactTaskDetail
