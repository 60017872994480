import * as React from "react"

export const CAMPAIGN_STATUS_RUNNING = "Running"
export const CAMPAIGN_STATUS_PAUSED = "Paused"

export const CAMPAIGN_OPT_DOC = "Doc"
export const CAMPAIGN_OPT_PAYMENT = "Payment"
export const CAMPAIGN_OPT_SCHEDULER = "Scheduler"
export const CAMPAIGN_OPT_CHAT_BOT = "ChatBot"
export const CAMPAIGN_OPT_CLICK = "ClickThrough"

export const CAMPAIGN_OPT_OPTIONS = [
  {
    type: CAMPAIGN_OPT_DOC,
    label: "eSign / Complete a Doc / Form",
  },
  {
    type: CAMPAIGN_OPT_PAYMENT,
    label: "Make a Payment (Buy a Product)",
  },
  {
    type: CAMPAIGN_OPT_SCHEDULER,
    label: "Schedule an Appointment",
  },
  {
    type: CAMPAIGN_OPT_CHAT_BOT,
    label: "Chat with my Bot",
  },
  {
    type: CAMPAIGN_OPT_CLICK,
    label: "Click Through / View Content",
  },
]

export const REQUEST_OPTION_SEQUENCE = "Sequence"
export const REQUEST_OPTIONS = [
  {
    type: "",
    icon: "",
    label: "= DIRECT CONTACT / OWNED MEDIA =",
  },
  {
    type: "Sequence",
    icon: "bi bi-ellipsis-h",
    label: REQUEST_OPTION_SEQUENCE,
  },
  {
    type: "Email",
    icon: "bi bi-envelope",
    label: "Email",
  },
  {
    type: "SMS",
    icon: "bi bi-mobile",
    label: "SMS",
  },
  {
    type: "Phone",
    icon: "bi bi-mobile",
    label: "Phone Calls",
  },
  {
    type: "Postal Mail",
    icon: "bi bi-envelope-open",
    label: "Postal / Direct Mail",
  },
  {
    type: "Past Client / Repeat Client",
    icon: "bi bi-envelope-open",
    label: "Past Client / Repeat Client",
  },
  {
    type: "",
    icon: "",
    label: "= REFERRAL & VIRAL (UNPAID) =",
  },
  {
    type: "Referral by Customer / Client",
    icon: "",
    label: "Referral by Customer / Client",
  },
  {
    type: "Referral by Other Source (Unpaid)",
    icon: "",
    label: "Referral by Other Source (Unpaid)",
  },
  {
    type: "Social / Viral (Unpaid)",
    icon: "",
    label: "Social / Viral (Unpaid)",
  },
  {
    type: "",
    icon: "",
    label: "= ONLINE PAID MEDIA =",
  },
  {
    type: "PPC / CPC (Per Click)",
    icon: "",
    label: "PPC / CPC (Per Click)",
  },
  {
    type: "CPM Online Display Media",
    icon: "",
    label: "CPM Online Display Media",
  },
  {
    type: "Paid Social / Native Ads",
    icon: "",
    label: "Paid Social / Native Ads",
  },
  {
    type: "CPA / CPL (Cost Per Action, Lead)",
    icon: "",
    label: "CPA / CPL (Cost Per Action, Lead)",
  },
  {
    type: "",
    icon: "",
    label: "= EARNED MEDIA ATTENTION =",
  },
  {
    type: "YouTube & Social Video",
    icon: "",
    label: "YouTube & Social Video",
  },
  {
    type: "Press & PR",
    icon: "",
    label: "Press & PR",
  },
  {
    type: "Social Unpaid",
    icon: "",
    label: "Social Unpaid",
  },
  {
    type: "SEO (Search Engine)",
    icon: "",
    label: "SEO (Search Engine)",
  },
  {
    type: "Inbound Content JV",
    icon: "",
    label: "Inbound Content JV",
  },
  {
    type: "",
    icon: "",
    label: "= OFFLINE =",
  },
  {
    type: "JV / Biz Dev / Paid Referrals",
    icon: "",
    label: "JV / Biz Dev / Paid Referrals",
  },
  {
    type: "Live Events",
    icon: "",
    label: "Live Events",
  },
  {
    type: "TV",
    icon: "",
    label: "TV",
  },
  {
    type: "Radio",
    icon: "",
    label: "Radio",
  },
  {
    type: "Display Ad",
    icon: "",
    label: "Display Ad",
  },
  {
    type: "",
    icon: "",
    label: "= OTHER =",
  },
  {
    type: "Imported Leads",
    icon: "",
    label: "Imported Leads",
  },
  {
    type: "Live Transfer Lead",
    icon: "",
    label: "Live Transfer Lead",
  },
]
