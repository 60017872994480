import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

const ContactSharedUsers = () => {
  return (
    <div className="SwiftCloudSharedWithUsers">
      <ul>
        <li>
          <a href="#" data-bs-toggle="tooltip" title="Rosa">
            <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/Rosa.jpg`} />
          </a>
        </li>
        <li>
          <a href="#" data-bs-toggle="tooltip" title="Tejas">
            <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/tejas.png`} />
          </a>
        </li>
        <li>
          <a href="#" data-bs-toggle="tooltip" title="Susan">
            <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/susan.jpg`} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ContactSharedUsers
