import * as React from "react"
import { Link } from "gatsby"
import TimeAgo from "timeago-react"
import { timeFormatter } from "../../../services/timeHelper"
import ContactQuickTools from "./contactQuickTools"
import ContactSharedUsers from "./contactSharedUsers"
import TagListView from "../../Global/Tags/tagListView"
import { useEffect, useState } from "react"
import ContactTaskDetail from "./contactTaskDetail"

const ContactListRow = ({
  contact,
  groupId,
  campaignId,
  setDeleteContactRow,
  setIsEscalateContact,
  setSelectedContacts,
  selectedContacts,
}) => {
  const contactId = "C_ID" + contact.id
  // [deleteContactRow, setDeleteContactRow] = useState(false);

  // useEffect(() => {
  //     if(deleteContactRow) {
  //         setDeleteContactRow(false)
  //         deleteContact(contact.id)
  //     }
  // })

  let profilePicture = (
    <i className={`bi bi-${contact.contactType === "human" ? "person" : "building"}-fill`} />
  )
  if (contact.profilePic) {
    profilePicture = (
      <img src={process.env.GATSBY_WRANGLER_URL + contact.profilePic} alt={contact.profilePic} />
    )
  }

  return (
    <tr>
      <td className="tableColChkDel">
        <div className="tableColChkDelBox">
          <input
            type="checkbox"
            name="fileid[]"
            id={contactId}
            className="css-checkbox"
            onChange={e => {
              if (e.target.checked) {
                if (!selectedContacts.includes(contact.id)) {
                  setSelectedContacts([...selectedContacts, contact.id])
                }
              } else {
                const selectedFiles = selectedContacts.filter(select => select !== contact.id)
                selectedFiles === [] ? setSelectedContacts([]) : setSelectedContacts(selectedFiles)
              }
            }}
          />
          <label htmlFor={contactId} className="css-checkbox-label" />
          <span className="tableColChkSpan btn-secondary">{profilePicture}</span>
        </div>
      </td>
      <td className="nowrap">
        <Link to={`/contacts/detail/${contact.id}`}>
          {contact.name}
          {/* - {contact.id}*/}
        </Link>
        &nbsp;
        {/*<ContactSharedUsers />*/}
        {contact.companyName && (
          <div className="iconTextBoxContainer contactBox">
            <div className="iconTextBox">
              <span className="iconTextBoxIcon">
                <i className="bi bi-building" />
              </span>
              <a
                href="#"
                className="iconTextBoxText"
                onClick={e => {
                  e.preventDefault()
                }}
              >
                {contact.companyName}
              </a>
            </div>
          </div>
        )}
        {contact.taskContacts.length > 0 && <ContactTaskDetail contactTaskData={contact.taskContacts} />}
        <ContactQuickTools contact={contact} />
        &nbsp;
        {contact.tags.length > 0 && <TagListView tags={contact.tags} contactId={contact.id} />}
      </td>

      {groupId && <td>{contact.raveScore}</td>}
      {campaignId && <td>{contact.raveScore}</td>}

      <td className="text-end d-none d-lg-table-cell">
        <div className="lastActivityBox">
          <TimeAgo datetime={timeFormatter(contact.updatedAt)} />
        </div>
        <div className="statusActionBox">
          {groupId && (
            <a
              className="btn btn-sm btn-primary color-white"
              data-toggle="tooltip"
              data-placement="bottom"
              data-animation="false"
              title="Escalate Contact"
              onClick={() => {
                setDeleteContactRow(contact.id)
                setIsEscalateContact(true)
              }}
            >
              <i className="bi bi-box-arrow-up-right" />
            </a>
          )}{" "}
          <a
            className="btn btn-delete"
            data-toggle="tooltip"
            data-placement="bottom"
            data-animation="false"
            title="Delete"
            onClick={() => {
              setDeleteContactRow(contact.id)
            }}
          >
            <i className="bi bi-trash-fill" />
          </a>
        </div>
      </td>
    </tr>
  )
}

export default ContactListRow
