import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Detail from "../../../components/Campaign/Detail/detail"
import ReduxWrapper from "../../../redux/reduxWrapper"

const CampaignDetail = props => {
  return <PrivateRoute component={Detail} location={props.location} selectedCampaignId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<CampaignDetail {...props} />} />
export default WrappedPage
