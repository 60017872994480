import * as React from "react"
import { Link } from "gatsby"
import { REQUEST_OPTION_SEQUENCE } from "../Listing/addCampaignHelper"

const CampaignGoal = ({ campaign }) => {
  return (
    <div className="row g-0">
      <div className="col-lg-12 p-3 campaignGoal">
        <div className="alert alert-warning mb-0" role="alert">
          <i className="bi bi-trophy-fill" /> Campaign Goal: I want&nbsp;
          <Link to={`/contacts/groups/detail/${campaign.contactGroup.id}`} className="text-dash-underline">
            {campaign.contactGroup.name}
          </Link>
          &nbsp; to Buy a {campaign.type}, specifically&nbsp;
          {campaign.doc && (
            <>
              <Link to={`/drive/doc/${campaign.doc.id}`}>{campaign.doc.docName}</Link>&nbsp;
            </>
          )}
          & I'll request it via&nbsp;
          {campaign.requestVia}&nbsp;
          {campaign.requestVia === REQUEST_OPTION_SEQUENCE && (
            <>
              <a href="LINK_TO_SEQUENCE" className="text-dash-underline">
                SequenceNameHere
              </a>
            </>
          )}
          . (Status: {campaign.status})
        </div>
      </div>
    </div>
  )
}

export default CampaignGoal
