import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const MoveToModal = ({ moveTo, currentGroupId, setMoveTo, groupList, selectedContacts }) => {
  const [groupId, setGroupId] = useState()
  const handleClose = () => {
    setMoveTo(false)
  }

  const handleMoveFiles = () => {
    if (!groupId) {
      return false
    }
    AxiosInstance.post(`/contact/move-to`, {
      current_group_id: currentGroupId,
      parent_id: parseInt(groupId),
      ids: selectedContacts,
    }).then(response => {
      handleClose()
      window.location.reload()
    })
  }

  return (
    <Modal show={moveTo} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>
            <i className="bi bi-people-fill" /> Select Group
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row align-items-center">
          <div className="col-sm-12">
            <select className="form-select" value={groupId} onChange={e => setGroupId(e.target.value)}>
              <option>== List of Groups ==</option>
              <option value={0}>{"<< ROOT"}</option>
              {groupList.length > 0 &&
                groupList.map((group, index) => {
                  return (
                    <option key={index} value={group.id}>
                      {group.name}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={handleClose}>
          <i className="bi bi-x-lg" /> Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={handleMoveFiles}>
          <i className="bi bi-check-lg" /> Move File(s)
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default MoveToModal
